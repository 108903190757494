import $ from 'jquery';
import 'jquery-ui/ui/widgets/datepicker';
import 'jquery-ui/ui/i18n/datepicker-fr';

let date = $(".js-date");
if (date.length > 0) {
  date.each(function () {
    let dates = JSON.parse($(this).attr("data-date"));
    let calendarField = $(this).find(".calendar");

    calendarField.datepicker({
      changeYear: true,
      yearRange: "-124:+5",

      onSelect: function () {
        let selectedDate = $(this).datepicker("getDate");
        let selectedDateString = $.datepicker.formatDate("yy-mm-dd", selectedDate);
        let selectedDatePlaceholder = $.datepicker.formatDate("dd.mm.yy", selectedDate);
        let placeholder = $(this).find(".js-date-placeholder");
        let placeholderLable = placeholder.attr("data-label");
        let fromInput = $(this).siblings(".js-date-from");
        let toInput = $(this).siblings(".js-date-to");
        let calendarIcon = $(this).find(".js-date-icon");
        let calendarReset = $(this).prev(".js-calendar-reset");
        let calendar = $(this);
        let datePicker = $(this).find(".ui-datepicker");
        let buttonPrev = $(this).find(".ui-datepicker-prev");
        let buttonNext = $(this).find(".ui-datepicker-next");

        function findClosestUnavailableEndDate(selectedDate) {
          let closestEndDate = null;
          for (let i = 0; i < dates.length; i++) {
            let From = dates[i].from.split("/");
            let To = dates[i].to.split("/");
            let FromDate = new Date(From[2], From[1] - 1, From[0]);
            let ToDate = new Date(To[2], To[1] - 1, To[0]);

            if (selectedDate >= FromDate && selectedDate < ToDate) {
              closestEndDate = ToDate;
              break;
            }
          }
          return closestEndDate;
        }

        function setDateRange() {
          if (!fromInput.val()) {
            fromInput.val(selectedDateString);
            placeholder.html(`<span>De : ${selectedDatePlaceholder}</span>`);
            calendar.datepicker("option", "minDate", selectedDate);

            let closestEndDate = findClosestUnavailableEndDate(selectedDate);
            if (closestEndDate !== null) {
              calendar.datepicker("option", "maxDate", closestEndDate);
            }
          } else if (!toInput.val()) {
            toInput.val(selectedDateString);
            placeholder.html(`${placeholder.text()} <span class="end-date">À : ${selectedDatePlaceholder} </span>`);
            datePicker.hide();
            datepickerNavAccessibility(false, buttonPrev, buttonNext);
            addRemoveActiveClass(false, datePicker);
            calendarIcon.hide();
            calendarReset.show();
          }
        }

        function resetDateRange() {
          calendarReset.on("click", () => {
            calendar.datepicker("option", "minDate", null);
            calendar.datepicker("option", "maxDate", null);
            datePicker.hide();
            datepickerNavAccessibility(false, buttonPrev, buttonNext);
            addRemoveActiveClass(false, datePicker);
            fromInput.val("");
            toInput.val("");
            placeholder.text(placeholderLable);
            calendarReset.hide();
            calendarIcon.show();
          })
        }

        if ($(this).hasClass("calendar-default")) {
          placeholder.text(selectedDatePlaceholder);
          $(this).prev().val(selectedDateString);
          datePicker.hide();
          datepickerNavAccessibility(false, buttonPrev, buttonNext);
          addRemoveActiveClass(false, datePicker);
        } else {
          setDateRange();
          resetDateRange();
        }
      },
    });

    let datePicker = $(this).find(".ui-datepicker");
    let buttonPrev = $(this).find(".ui-datepicker-prev");
    let buttonNext = $(this).find(".ui-datepicker-next");

    datePicker.hide();
    datepickerNavAccessibility(false, buttonPrev, buttonNext);
    addRemoveActiveClass(false, datePicker);

    calendarField.on("click", function () {
      $(this).find(".ui-datepicker").show();
      datepickerNavAccessibility(true, $(this).find(".ui-datepicker-prev"), $(this).find(".ui-datepicker-next"));
      addRemoveActiveClass(true, $(this).find(".ui-datepicker"));
    });

    $(document).on("click", function (e) {
      if (e.target.closest(".calendar") === null && e.target.closest(".ui-datepicker") === null && e.target.closest(".ui-datepicker-header") === null) {
        datePicker.hide();
        datepickerNavAccessibility(false, buttonPrev, buttonNext);
        addRemoveActiveClass(false, datePicker);
      }
    });

    $(document).on("keydown", function (e) {
      if (e.key === 'Escape' || e.keyCode === 27) {
        datePicker.hide();
        datepickerNavAccessibility(false, buttonPrev, buttonNext);
        addRemoveActiveClass(false, datePicker);
      }
    });

    function datepickerNavAccessibility(add = true, buttonPrev, buttonNext) {
      if (add) {
        if (buttonPrev) {
          buttonPrev.attr('tabindex', '0');
          buttonPrev.attr('data-enter-click-js', '');
        }
        if (buttonNext) {
          buttonNext.attr('tabindex', '0');
          buttonNext.attr('data-enter-click-js', '');
        }
      } else {
        if (buttonPrev) {
          buttonPrev.attr('tabindex', '-1');
        }
        if (buttonNext) {
          buttonNext.attr('tabindex', '-1');
        }
      }
    }

    function addRemoveActiveClass(add = true, datePicker) {
      if (!datePicker) return;

      let parentEl = $(datePicker).closest('.js-date');
      if (!parentEl.length) return;

      let dateInput = parentEl.find(`input[type="date"]`);

      if (!add && (dateInput.val())) {
        return;
      }

      if (add) {
        parentEl.addClass('is-active');
      } else {
        parentEl.removeClass('is-active');
      }
    }
  });
}
