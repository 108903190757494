/* eslint-disable */
import AOS from 'aos';

const ContentLoadingAnimations = {
  init: function () {
    const self = this;
    const preloader = document.querySelector('[data-preloader-js]');
    if (preloader) {
      setTimeout(function () {
        self.hidePreloader();
        self.showHeader();
        AOS.init();
      }, 500);
    } else {
      this.hidePreloader();
      this.showHeader();
      AOS.init();
    }
  },

  hidePreloader: function () {
    const preloader = document.querySelector('[data-preloader-js]');
    if (preloader) {
      preloader.classList.remove("is-active");
    }
  },

  showHeader: function () {
    const header = document.querySelector('[data-header-js]');

    header.classList.add('is-active');
  },
}

export default ContentLoadingAnimations;
