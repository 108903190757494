import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import optimizedResize from './helpers/optimized-resize';
import updateScrollTriggerOnResize from './helpers/update-scroll-trigger-on-resize.js';
import AppHeight from './parts/components/app-height';
import Header from './parts/layout/header';
import anchorsScroll from './parts/components/anchor-scroll';
import customParallax from './parts/components/custom-parralax';
import {initLabel} from "./helpers/floatlabel";
import contentLoadingAnimations from './parts/layout/content-loading-animations';
import CommonAccessibility from './parts/layout/accessibility';

import "./preloader";
import "./custom-select";
import "./form";
import "./date";

gsap.registerPlugin(ScrollTrigger);

document.addEventListener("DOMContentLoaded", function () {
  updateScrollTriggerOnResize(ScrollTrigger);
  CommonAccessibility.init();
  contentLoadingAnimations.init();
  optimizedResize();
  AppHeight.init();
  Header.init(ScrollTrigger);
  anchorsScroll.init();
  customParallax.init(gsap);
  initLabel();
})
