/* eslint-disable */


const Header = {
  init: function (ScrollTrigger) {
    this.headerAnim(ScrollTrigger);
    this.mobileNav();
  },

  headerAnim: function (ScrollTrigger) {
    const header = document.querySelector("[data-header-js]");
    if (!header) return;

    ScrollTrigger.create({
      trigger: document.body,
      start: "top top",
      end: "bottom top",
      onUpdate: (self) => {
        const currentScrollTop = self.scroller.scrollTop || window.pageYOffset;

        if (currentScrollTop > 50) {
          if (self.direction === 1) {
            header.classList.add("is-hidden");
          } else if (self.direction === -1) {
            header.classList.remove("is-hidden");
          }
        } else {
          header.classList.remove("is-hidden");
        }

        if (currentScrollTop > 100) {
          header.classList.add("is-small");
        } else {
          header.classList.remove("is-small");
        }
      },

    });
  },

  mobileNav: function () {
    const body = document.querySelector("body");
    const openNavTrigger = document.querySelector("[data-open-nav-js]");
    if (!openNavTrigger) return;

    openNavTrigger.addEventListener("click", () => {
      if (body.classList.contains("navigationOpen")) {
        this.closeMobileNav();
      } else {
        this.openMobileNav();
      }
    });
  },

  openMobileNav: function () {
    const body = document.querySelector("body");
    const html = document.querySelector("html");

    html.classList.add("overflowHidden");
    body.classList.add("overflowHidden");
    body.classList.add("navigationOpen");
  },

  closeMobileNav: function () {
    const body = document.querySelector("body");
    const html = document.querySelector("html");

    html.classList.remove("overflowHidden");
    body.classList.remove("overflowHidden");
    body.classList.remove("navigationOpen");
  },
};

export default Header;
