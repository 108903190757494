let select = document.querySelectorAll(".js-select");
let options = document.querySelectorAll(".option-container .option");


select.forEach((e) => {
e.onclick = () => {
    let selectContainer = e.parentElement;
    let optionContainer = selectContainer.querySelector(".option-container");
    let options = optionContainer.querySelectorAll(".option");
    let elCount = options.length;
    let sumHeight = 0;

    selectContainer.classList.toggle("active");

    options.forEach(el => {
    sumHeight =+ el.offsetHeight * elCount;
    });

    if (!selectContainer.classList.contains("active")) {
    optionContainer.style.height = `0px`;
      options.forEach(el => {
       el.setAttribute('tabindex', '-1');
      });
    } else {
    optionContainer.style.height = `${sumHeight}px`;
      options.forEach(el => {
        el.setAttribute('tabindex', '0');
      });
    }
};
});

options.forEach((e) => {
    e.addEventListener("click", () => {
        selectOption(e);
    });
});

function selectOption(e) {
    let selectContainer = e.parentElement.parentElement;
    let optionContainer = selectContainer.querySelector(".option-container");
    let input = selectContainer.querySelector(".input-select");
    let select = selectContainer.querySelector(".select");

    input.value = e.innerText;
    selectContainer.classList.remove("active");
    optionContainer.style.height = `0px`;
    select.classList.remove("active");
    options.forEach((e) => {
        e.classList.remove("selected");
    });
    e.classList.add("selected");
}
