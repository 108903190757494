/* eslint-disable */
export default (ScrollTrigger) => {
  let windowWidth = window.innerWidth;
  window.addEventListener('optimizedResize', () => {
    if (window.innerWidth !== windowWidth) {
      ScrollTrigger.refresh();
      windowWidth = window.innerWidth;
    }
  });
}
