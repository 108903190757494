import {gsap} from 'gsap';

const CommonAccessibility = {
  init: function () {
    this.enterClickDataAttr();
    this.removeTabindexInsideDataAttr();
    this.removeTabindexDataAttr();
    this.scrollBottomOnFooterLinksFocus();
    this.skipLink();
    // this.enableTextFocus();
  },

  enterClickDataAttr: function () {
    const parentElement = document.body;

    parentElement.addEventListener('keypress', function (event) {
      if (event.target.hasAttribute('data-enter-click-js') && (event.key === 'Enter' || event.keyCode === 13)) {
        event.target.click();
      }
    });
  },

  removeTabindexInsideDataAttr: function () {
    let els = document.querySelectorAll('[data-remove-tabindex-inside-js]');
    if (!els.length) return;

    els.forEach((el) => {
      let insideEls = el.querySelectorAll('a', 'button', 'input');
      if (!insideEls.length) return;

      insideEls.forEach((insideEl) => {
        this.removeTabindex(insideEl);
      });
    });
  },

  removeTabindexDataAttr: function () {
    let els = document.querySelectorAll('[data-remove-tabindex-js]');
    if (!els.length) return;

    els.forEach((el) => {
      this.removeTabindex(el);
    });
  },

  removeTabindex: function (el) {
    if (!el) return;

    el.setAttribute('tabindex', -1);
  },

  scrollBottomOnFooterLinksFocus: function () {
    let footerLinks = document.querySelectorAll('footer a');

    document.addEventListener('keydown', function (event) {
      if (event.key === 'Tab' || event.keyCode === 9) {
        checkActiveLinks();
      }
    });

    function checkActiveLinks() {
      requestAnimationFrame(function () {
        let isAnyFocused = Array.from(footerLinks).some(function (el) {
          return el === document.activeElement;
        });

        if (isAnyFocused && !isDocumentScrolledToBottom()) {
          gsap.to(window, {
            duration: 1,
            scrollTo: {
              y: document.body.scrollHeight,
              offsetY: 0,
            },
            ease: 'power2.inOut',
          });
        }
      });
    }

    function isDocumentScrolledToBottom() {
      let documentHeight = Math.max(
        document.body.scrollHeight,
        document.documentElement.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.offsetHeight,
        document.body.clientHeight,
        document.documentElement.clientHeight
      );

      let scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      let windowHeight = window.innerHeight || document.documentElement.clientHeight;

      return documentHeight - scrollTop === windowHeight;
    }
  },

  skipLink: function () {
    let skipLink = document.querySelector('.skip-link');
    if (!skipLink) return;
    skipLink.addEventListener('click', function (event) {
      event.preventDefault();
      let target = document.querySelector(this.getAttribute('href'));
      if (target) {
        target.setAttribute('tabindex', '-1');
        target.focus();
      }
    });
  },

  enableTextFocus: function () {
    // Select the text elements you want to make focusable.

    let textElements = document.querySelectorAll('p, li, h1, h2, h3, h4, h5, h6, a, button, input, textarea, select');

    // Loop through each element and set tabindex to -1
    textElements.forEach(function (element) {
      element.setAttribute('tabindex', '0');
    });
  },
};

export default CommonAccessibility;
