const customParallax = {

  init: function (gsap) {
    const parallaxEls = document.querySelectorAll('[data-parallax-el-js]');
    if (!parallaxEls || parallaxEls.length === 0) return;

    parallaxEls.forEach((el) => {
      const parallaxWrap = el.closest('[data-parallax-wrap-js]');
      const direction = el.getAttribute('data-parallax-direction-js');
      const yFrom = direction === 'reverse' ? -60 : 60;
      const yTo = direction === 'reverse' ? 60 : -60;

      if (!parallaxWrap) return;

      gsap.fromTo(el, {
        y: yFrom,
      }, {
        y: yTo,
        scrollTrigger: {
          trigger: parallaxWrap,
          start: "top bottom",
          end: "bottom top",
          scrub: true,
          // markers: true,
        },
        ease: "none",
      });
    })
  },
}

export default customParallax;
