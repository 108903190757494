import $ from 'jquery';

const form = {

  maxFileSize: function (value) {
    let max = value;
    return max / (1024 * 1024);
  },

  removeUploadedFile: function (parent, defaultLabel) {
    let btn = document.createElement(".form__submit button");
    btn.innerHTML = "x";
    !parent.querySelector(".form__submit button") && parent.append(btn),
      parent.classList.add("custom-file_uploaded");

    btn.addEventListener("click", function () {
      parent.querySelector("input").value = "";
      parent.querySelector("label").textContent = defaultLabel;
      parent.classList.remove("custom-file_uploaded");
      btn.remove();
    });
  },

  customInput: function () {
    let inputs = document.querySelectorAll(".js-input");
    let label;
    let defaultLabel;
    let maxSize = 20971520;

    inputs.forEach((input) => {
      input.addEventListener("change", function () {
        defaultLabel = this.nextElementSibling.textContent;
        if (this.classList.contains("js-input-file")) {
          if (this.files[0].size > maxSize) {
            label = `Maximum file size is ${form.maxFileSize(maxSize)} MB`;
          } else {
            label = input.files[0].name;
          }
          form.removeUploadedFile(this.parentElement, defaultLabel);
        } else {
          label = this.value;
        }
        this.nextElementSibling.textContent = label;
      });
    });
  },

  validatePhone: function () {
    let inputs = document.querySelectorAll('input[type="tel"]');
    inputs.forEach((input) => {
      input.addEventListener("input", function () {
        let value = this.value;
        let newValue = "";
        for (let i = 0; i < value.length; i++) {
          if (!isNaN(parseInt(value[i])) || value[i] === "+") {
            newValue += value[i];
          }
        }
        this.value = newValue;
      });
    });
  },

  validateNumbers: function () {
    let inputs = document.querySelectorAll('input[type="number"]');
    inputs.forEach((input) => {
      input.addEventListener("input", function () {
        let value = this.value;
        let newValue = "";
        for (let i = 0; i < value.length; i++) {
          if (!isNaN(parseInt(value[i]))) {
            newValue += value[i];
          }
        }
        this.value = newValue;
      });
    });
  },
};

form.customInput();
form.validatePhone();
form.validateNumbers();

$('form.tabs-form').on('submit', function (event) {
  event.preventDefault();

  let formValid = true;
  let allEmpty = true;
  let message = $(this).find('.js-validation-message');
  let email = $(this).find('.js-input-email');
  let date = $(this).find('.js-date-validation');
  let dateMessage = $('.js-validation-date');

  if (date.length > 0) {
    let fromDate = date.find('.js-date-from');
    let toDate = date.find('.js-date-to');

    if (fromDate.val() === '' || toDate.val() === '') {
      formValid = false;
      dateMessage.show()
    } else {
      dateMessage.hide()
    }
  }

  $(this).find('[data-required="true"]').each(function () {
    if ($.trim($(this).val()) === '') {
      formValid = false;
      $(this).parent().addClass('error');
    } else {
      $(this).parent().removeClass('error');
    }
  });

  $(this).find('[data-required]').each(function () {
    if ($.trim($(this).val()) !== '') {
      allEmpty = false;
    }
  });

  if (allEmpty) {
    formValid = false;
    message.addClass('active');
    $(this).find('.js-message-success').removeClass('active');
  } else {
    message.removeClass('active');
  }

  if (email.length > 0) {
    let emailValue = email.val().trim();
    let emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(emailValue)) {
      $(this).find('.js-email-validation').show();
      email.parent().addClass('error');
      formValid = false;
    } else {
      $(this).find('.js-email-validation').hide();
      email.parent().removeClass('error');
    }
  }

  if (!formValid) {
    const error = $(this).find('.error')[0] ? $(this).find('.error')[0] : $(this).find('.input-group input[type="email"]')[0];
    const input = $(error).find('input')[0] ? $(error).find('input')[0] : error;
    error.scrollIntoView({behavior: 'smooth'});
    input.focus();
    return;
  }

  let formData = new FormData(this);

  formData.append('action', 'submit_form');

  let ccEmail = $(this).find('input[type="email"]').val();
  let introText = $(this).find('input[name="introduction"]').val();
  let concText = $(this).find('input[name="conclusion"]').val();
  let dataFormID = $(this).data('form-id');

  formData.append('cc_email', ccEmail);

  if (introText) {
    formData.append('intro_text', introText);
  }

  if (concText) {
    formData.append('conc_text', concText);
  }

  if (dataFormID) {
    formData.append('data_form_id', dataFormID);
  }

  let form = $(this);
  let formWrap = form.closest('div');
  let formWrapBooking = form.closest('.booking-form');
  let filePlaceholder = $('.js-file-placeholder');

  formWrap.append('<div class="loader-ring-wrapper active"><div class="loader-ring"><div></div><div></div><div></div><div></div></div></div>');

  $.ajax({
    url: themeVars.ajax_url,
    type: 'POST',
    data: formData,
    processData: false,
    contentType: false,
    success: function () {
      $('.loader-ring-wrapper.active').remove();
      form.addClass('success');
      if (formWrapBooking) {
        formWrapBooking.addClass('success');
      }
      form.find('.js-message-success').addClass('active');
      form[0].reset();
      $('.js-date-placeholder').text($('.js-date-placeholder').attr('data-label'));
      filePlaceholder.text(filePlaceholder.attr('data-label'));
      filePlaceholder.next().remove();
      filePlaceholder.parent().removeClass("custom-file_uploaded");
      form.find('.js-email-validation').hide();
      form.find('.js-calendar-reset').hide();
      form.find('.js-date-icon').show();
      setTimeout(() => {
        form.find('.js-message-success')[0]?.parentElement.parentElement.scrollIntoView({behavior: 'smooth'});
      }, 300);
    },
    error: function (jqXHR, textStatus, errorThrown) {
      $('.loader-ring-wrapper.active').remove();
      console.log(jqXHR, textStatus, errorThrown);
    },
  });
});
